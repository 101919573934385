/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react'

import { ParticipantCount } from './ParticipantCount'

import { serviceCombinedExperienceCardInfo } from '../ServiceCombinedExperienceList.style'
import { useMemo } from 'react'

export const ServiceCombinedExperienceCardInfo = ({
  experiencePaxInfo = {},
  feeList = [],
  startTime = null,
  endTime = null,
  isPriceTotalizer = true,
  index = 0
}) => {
  const { quantity, total } = experiencePaxInfo

  const feeListByAlphabeticalOrder = useMemo(() => feeList.sort((a, b) => (a?.name || '---').localeCompare((b?.name || '---'))), [feeList])

  return (
    <div css={serviceCombinedExperienceCardInfo} className={!isPriceTotalizer && 'pax__totalizer'}>
      <ParticipantCount
        dataIndex={index}
        quantity={quantity}
        total={total}
        isPriceTotalizer={isPriceTotalizer}
        feeList={feeListByAlphabeticalOrder}
        timeRange={{ startTime, endTime }}
      />
    </div>
  )
}
