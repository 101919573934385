/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react'

import { priceAndParticipants, participantCount, paxSummaryContainer } from '../ServiceCombinedExperienceList.style'

import { formatMoney } from '@bonitour/common-functions'

export const ParticipantCount = ({
  timeRange = {},
  feeList = [],
  quantity = 0,
  total = 0,
  dataIndex = 0,
  isPriceTotalizer = true
}) => {
  const { startTime = null, endTime = null } = timeRange

  if (isPriceTotalizer) {
    return (
      <div css={priceAndParticipants}>
        <div css={participantCount(dataIndex)}>
          <span>
            {quantity}
          </span>
        </div>
        <span>{formatMoney(total)}</span>
      </div>
    )
  }

  return (
    <div css={paxSummaryContainer}>
      <p className='time__label'>
        {startTime} - {endTime}
      </p>
      {feeList.map(({ name, quantity }) => {
        const safeName = name || '--'
        const safeQuantity = quantity || 0
        return (
          <p key={`fee-${safeName}-${dataIndex}`}>{safeQuantity} x {safeName.toLowerCase()}</p>
        )
      })}
    </div>
  )
}
